const untrailingslashit = str => str.endsWith("/") || str.endsWith("\\") ? untrailingslashit(str.slice(0, -1)) : str;
const trailingslashit = str => `${untrailingslashit(str)}/`;

// Allows to make an interface extension and make some properties optional (https://git.io/JeK6J)

function simpleHash(s) {
  let a = 0;
  for (const char of s) {
    const charCode = char.charCodeAt(0);
    a = (a << 5 >>> 0) - a + charCode;
    // Use a bitwise AND with a large prime number to ensure the result stays within 32-bit bounds.
    // JavaScript bitwise operations automatically convert operands to 32-bit signed integers, so the result is already within 32-bit bounds.
    // The >>> 0 operation ensures the number is treated as unsigned, making it equivalent to PHP's behavior with 0x7fffffff.
    a = a & 0x7fffffff;
  }
  return a;
}
export { untrailingslashit, trailingslashit, simpleHash };