/* istanbul ignore file: we do not need to care about the entry point file as errors are detected through integration tests (E2E) */

/**
 * Entrypoint for plain website. This file is only loaded if the visitor needs to accept cookies.
 */

import { render } from "react-dom";
import { getOtherOptionsFromWindow, ensureBodyElement, getOptionsFromWindow } from "./utils";
import { listenTemplateOptIn, deleteCookiesServer, getCookieConsentManager } from "./others";
import { tcfCmpApi } from "./tcf";
import { gcmDispatcher } from "@devowl-wp/cookie-consent-web-client";
import { OPT_OUT_ALL_EVENT, printConsentUuidsToHTMLElement, createApi } from "@devowl-wp/cookie-consent-web-client";
import { getSsrPoweredByLink } from "@devowl-wp/react-cookie-banner";
import { options } from "preact";
import { idx, ready, yieldLazyLoad } from "@devowl-wp/react-utils";
import { simpleHash } from "@devowl-wp/utils";

// Needed to be used together with `fastdom`
options.requestAnimationFrame = requestAnimationFrame;
const {
  others: {
    frontend: {
      blocker
    },
    anonymousContentUrl,
    anonymousHash,
    pageRequestUuid4
  },
  publicUrl,
  chunkFolder
} = getOptionsFromWindow();
const oldFn = __webpack_get_script_filename__;
__webpack_public_path__ = anonymousHash ? anonymousContentUrl : `${publicUrl}${chunkFolder}/`;
__webpack_get_script_filename__ = chunkId => {
  const filename = oldFn(chunkId);
  const [basename, query] = filename.split("?");
  return anonymousHash ? `${simpleHash(anonymousHash + basename)}.js?${query}` : filename;
};
listenTemplateOptIn();
ready(() => {
  const {
    frontend: {
      isGcm
    }
  } = getOtherOptionsFromWindow();
  tcfCmpApi();

  /* onlypro:start */
  if (process.env.PLUGIN_CTX === "pro" && isGcm) {
    gcmDispatcher(getCookieConsentManager().getOptions());
  }
  /* onlypro:end */
}, "interactive");
ready().then(() => {
  const poweredLink = getSsrPoweredByLink(`${pageRequestUuid4}-powered-by`);
  const element = ensureBodyElement(document.getElementById(pageRequestUuid4));
  printConsentUuidsToHTMLElement(".rcb-consent-history-uuids", getCookieConsentManager());
  document.addEventListener(OPT_OUT_ALL_EVENT, async _ref => {
    let {
      detail: {
        deleteHttpCookies
      }
    } = _ref;
    // Handle cookie deletion which needs to be done server-side (e. g. HTTP cookies)
    deleteHttpCookies.length && setTimeout(() => deleteCookiesServer(deleteHttpCookies), 0);
  });
  if (element) {
    const WebsiteBanner = yieldLazyLoad(import( /* webpackChunkName: "banner-ui", webpackMode: "lazy-once" */"./components/websiteBanner").then(_ref2 => {
      let {
        WebsiteBanner
      } = _ref2;
      return WebsiteBanner;
    }));
    render(h(WebsiteBanner, {
      poweredLink: poweredLink
    }), element);
  }
});

// Expose public API
const {
  wrapFn
} = createApi(getCookieConsentManager(), blocker);

// Wrap some functions
const fnGetWindow = () => window;
wrapFn({
  object: () => idx(window, window => window.mkdf.modules.destinationMaps.mkdfGoogleMaps),
  key: "getDirectoryItemsAddresses"
}, "functionBody");
wrapFn([
// [Plugin Comp] https://wordpress.org/plugins/bold-page-builder/
{
  object: fnGetWindow,
  key: "bt_bb_gmap_init_new"
}, {
  object: fnGetWindow,
  key: "bt_bb_gmap_init_static_new"
}], ["unblock", "google.com/maps"]);

// [Plugin Comp] PixelYourSite
wrapFn({
  object: () => idx(window, window => window.pys.Utils),
  key: "manageCookies"
}, ["consent", "http", "pys_first_visit", "*"]);