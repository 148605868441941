import { getDefaultDecision, getUserDecision } from "..";
/**
 * Main class to manage cookie consents within your application.
 */
class CookieConsentManager {
  constructor(options) {
    this.options = void 0;
    const {
      decisionCookieName
    } = options;
    this.options = options;
    this.options.tcfCookieName = `${decisionCookieName}-tcf`;
    this.options.gcmCookieName = `${decisionCookieName}-gcm`;
  }
  async applyCookies(options) {
    const {
      apply: doApply
    } = await import( /* webpackChunkName: "banner-lazy", webpackMode: "lazy-once" */"../apply/apply");
    return doApply({
      ...options,
      ...this.options
    });
  }
  getUserDecision(onlyUptoDate) {
    const decision = getUserDecision(this.getOption("decisionCookieName"));
    return onlyUptoDate === true ? decision ? decision.revision === this.getOption("revisionHash") ? decision : false : false : decision;
  }
  getDefaultDecision() {
    let respectLegitimateInterests = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    return getDefaultDecision(this.options.groups, respectLegitimateInterests);
  }
  getOption(name) {
    return this.options[name];
  }
  getOptions() {
    return this.options;
  }
}
export { CookieConsentManager };