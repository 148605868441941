import { CookieConsentManager } from "@devowl-wp/cookie-consent-web-client";
import { getCmpVersion, getOtherOptionsFromWindow } from "../utils";
function getCookieConsentManager() {
  const {
    frontend: {
      decisionCookieName,
      groups,
      isGcm,
      revisionHash,
      setCookiesViaManager
    }
  } = getOtherOptionsFromWindow();
  if (!window.rcbConsentManager) {
    window.rcbConsentManager = new CookieConsentManager({
      // Access this way to avoid Proxy#get of `tcf`
      decisionCookieName,
      groups,
      isGcm,
      revisionHash,
      setCookiesViaManager,
      supportsCookiesName: "real_cookie_banner-test",
      skipOptIn: function (cookie) {
        const {
          presetId
        } = cookie;
        return ["amazon-associates-widget"].indexOf(presetId) > -1;
      },
      cmpId: process.env.TCF_CMP_ID,
      cmpVersion: getCmpVersion("major")
    });
  }
  return window.rcbConsentManager;
}
export { getCookieConsentManager };